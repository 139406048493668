import axios from "@/plugins/http.js";
class consultAPI {
  newsList(params) {
    //管理端——资讯列表
    return axios.get(`/news-list?${params}`);
  }
  list(params) {
    //管理端——资讯审核列表
    return axios.get(`/news/audit-list?${params}`);
  }
  addConsult(params) {
    //管理端——资讯提交
    return axios.post(`/news`, params);
  }
  delConsult(id) {
    //管理端——删除
    return axios.delete(`/news/delete/${id}`);
  }
  detail(id) {
    //管理端——资讯详情
    return axios.get(`/news/${id}`);
  }
  editConsult(params) {
    //管理端——资讯编辑
    return axios.put(`/news`, params);
  }
  testConsult(params) {
    //管理端——提审下架
    return axios.post(`/news/stop-start`, params);
  }
  checkConsultList(params) {
    //管理端——资讯审核列表
    return axios.get(`/news/audit-list?${params}`);
  }
  checkConsult(id, params) {
    // 审核
    return axios.post(`/news/audit/${id}`, params);
  }
  studyConsultList(params) {
    //学员端——资讯列表
    return axios.get(`/client/news-list?${params}`);
  }
  studyConsultDetail(id) {
    // 学员端 资讯详情
    return axios.get(`/client/news/${id}`);
  }
  getAuditLogList(params) {
    //获取审核记录
    return axios.get(`/audit-log-list?${params}`);
  }
}
export default new consultAPI();
