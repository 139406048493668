<template>
  <div class="container">
    <div class="hh_con_wrap">
      <div class="hh_con common_inner">
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '' }">
            <i @click="goBack" class="el-icon-back">返回</i>
          </el-breadcrumb-item>
          <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
        </el-breadcrumb> -->
        <!-- <img class="hh_img" :src="infoData.imgUrl" alt="" /> -->
        <p class="hh_title">
          {{ infoData.newsTitle }}
        </p>
        <p class="hh_time">
          <span>作者：{{ infoData.newsAuthor }}</span>
          <span>发布时间：{{ infoData.updateTime }}</span>
          <span>浏览量：{{ infoData.viewCount }}</span>
        </p>
        <div class="hh_content_wrap">
          <div class="hh_content" v-html="infoData.newsText"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/apis/backStageEndAPI/consultManageAPI";
import { EventBus } from "../../../assets/js/eventBus";
export default {
  name: "consultDetail",
  data() {
    return {
      id: "",
      infoData: {},
      studyLogForm: {
        courseId: "",
        device: "PC",
      },
    };
  },
  mounted() {
    EventBus.$emit("curIndex", 4);
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.studyLogForm.courseId = this.id;
      this.getConsultDetail(this.id);
    }
  },
  methods: {
    getConsultDetail(id) {
      API.studyConsultDetail(id).then((res) => {
        this.infoData = res;
      });
    },
    // goBack() {
    //   this.$router.go(-1)
    // }
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .el-breadcrumb {
    margin: 10px;
  }
}
.container {
  .hh_con_wrap {
    margin-top: 30px;
    width: 100%;
    .hh_con {
      background: #ffff !important;
      padding: 10px 0;
      .hh_title {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }
      .hh_time {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .hh_content_wrap {
        margin: 20px auto;
        width: 90%;
        border-top: 1px dotted #eaeaea;
        .hh_content {
          padding: 20px 0;
          color: #333333;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
